<template>
  <div>
    <div class="home box has-background-info-light has-text-info-dark">
        <div v-if="admins.includes($auth.user.email)" class="field is-grouped buttons">
            <div class="control">
                <button class="button is-link px-4 is-info" @click="editEmployees()">Mitarbeiter*innen & Akquise verwalten</button>
            </div>
        </div>
      <p>Hier sehen Sie das aktuelle Ranking der Vertragsabschlüsse für den Monat {{ monthNames[currentmonth] }} {{currentyear}} und den Vormonat.</p>
    </div>
    <div class="container">
      <div class="tabs is-toggle is-toggle-rounded">
          <ul>
              <li :class="{'is-active': period == 0}">
                  <a @click="setPeriod(0)">
                      <span>{{ monthNames[currentmonth] }} {{currentyear}}</span>
                  </a>
              </li>
              <li :class="{'is-active': period == 1}">
                  <a @click="setPeriod(1)">
                      <span>Vormonat</span>
                  </a>
              </li>
          </ul>
      </div>
      <div class="tabs is-toggle is-toggle-rounded">
          <ul>
              <li :class="{'is-active': location == 0}">
                  <a @click="filterAcquisitions(0)">
                      <span>Alle Clubs</span>
                  </a>
              </li>
              <li :class="{'is-active': location == 1}">
                  <a @click="filterAcquisitions(1)">
                      <span>Klosterneuburg</span>
                  </a>
              </li>
              <li :class="{'is-active': location == 2}">
                  <a @click="filterAcquisitions(2)">
                      <span>Rennweg</span>
                  </a>
              </li>
          </ul>
      </div>
      <div v-if="tmpEmployees.length===0">Aktuell liegen noch keine Einträge vor.</div>
      <div class="card-container">
        <div class="card" v-for="(employee, index) in tmpEmployees" :key="employee.id">
          <header class="card-header">
            <p class="card-header-title">
                {{employee.firstname}} {{employee.lastname}}
            </p>
            <span v-if="index == 0" class="icon">
             <font-awesome-icon icon="medal" />
            </span>
            <span v-else-if="index+1 == tmpEmployees.length" class="icon">
             <font-awesome-icon icon="person-running" />
            </span>
            <span v-else class="icon">
             <font-awesome-icon icon="dumbbell" />
            </span>
          </header>
          <div class="card-content">
            <!--<div class="media">
              <div class="media-left">
                <figure class="image is-96x96">
                  <img class="is-rounded" src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
                </figure>
              </div>
              <div class="media-content has-text-left">
                <br><br>
                <div class="subtitle is-6 has-text-weight-bold">{{employee.firstname}} {{employee.lastname}}  </div>
                <p class="subtitle is-6"></p>
              </div>
            </div>-->
            <div class="content">
              <acquisition-progress :target="employee.acquisitions[0].target" :actual="(parseInt(employee.acquisitions[0].actual) + parseInt(employee.acquisitions[0].comeback) + parseInt(employee.acquisitions[0].aftertrial)).toString()" :absolute="$auth.user.email === employee.email"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AcquisitionProgress from "../components/AcquisitionProgress.vue";
export default {
  name: "AcquisitionDashboard",
  components: {AcquisitionProgress},
  data: function () {
    return {
      employees: [],
      tmpEmployees: [],
      monthNames: ["Jänner","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
      admins: ["julia@starsfitness.at","service@starsfitness.at","philip.rosenstingl@starsfitness.at","michael@subversus.at","julia.koller@starsfitness.at"],
      priormonth: "",
      prioryear: "",
      currentmonth: "",
      currentyear: "",
      location: 0,
      period: 0
    }
  },
  mounted() {
    // Fetch employees data from the PHP API and update the "employees" array
    this.fetchAcquisitions();
    let currentDate = new Date();
    this.currentmonth = currentDate.getMonth();
    this.currentyear = currentDate.getFullYear();
    if(this.currentmonth == 0){
        this.priormonth = 11;
        this.prioryear--;
    } else {
        this.priormonth = this.currentmonth - 1;
        this.prioryear = this.currentyear;
    }
  },
  methods: {
    fetchAcquisitions() {
      fetch(`https://intranet.starsfitness.at/api/getAcquisitions.php?timestamp=` + Date.now())
          .then(response => response.json())
          .then(data => this.tmpEmployees = this.employees = data)
          .then(() => this.filterAcquisitions(this.location))
    },
    fetchAcquisitionsForMonthAndYear(month, year) {
        fetch(`https://intranet.starsfitness.at/api/getAcquisitions.php?month=` + month + `&year=` + year + `&timestamp=` + Date.now())
            .then(response => response.json())
            .then(data => this.tmpEmployees = this.employees = data)
            .then(() => this.filterAcquisitions(this.location))
    },
    editEmployees(){
        this.$router.push('/EmployeeManagement');
    },
    setPeriod(period){
      if(period == 1 && this.period != 1){
          this.fetchAcquisitionsForMonthAndYear(this.priormonth, this.prioryear);
          this.period = 1;
      } else if (period == 0 && this.period != 0){
          this.fetchAcquisitions();
          this.period = 0;
      }
    },
    filterAcquisitions(location){
        if(location == 0){
            this.tmpEmployees = [...this.employees];
            this.location = 0;
        } else if(location == 1){
            this.tmpEmployees = this.employees.filter(employee => (employee.location === "Klosterneuburg"));
            this.location = 1;
        } else if(location == 2){
            this.tmpEmployees = this.employees.filter(employee => (employee.location === "Wien Rennweg"));
            this.location = 2;
        }
    }
  }
}
</script>

<style scoped>
.card-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
  grid-gap: 0.5em;
  margin-top: 20px;
}
</style>