<template>
  <div id="app">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="https://www.starsfitness.at/">
          <img src="./assets/logo_starsfitness.png" height="28">
        </a>
        <a role="button" class="navbar-burger" @click="showNav = !showNav" :class="{ 'is-active': showNav }" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': showNav }">
        <div class="navbar-start">
          <a class="navbar-item is-tab" :class="{'is-active':active === 'home'}" @click="active = 'home'">
            <router-link to="/">Home</router-link>
          </a>
          <a v-if="$auth.isAuthenticated" class="navbar-item is-tab" :class="{'is-active':active === 'acquisitiondashboard'}" @click="active = 'acquisitiondashboard'">
              <router-link to="/acquisitiondashboard">Vertragsabschlüsse</router-link>
          </a>
          <a v-if="$auth.isAuthenticated" class="navbar-item is-tab" :class="{'is-active':active === 'damagereportform'}" @click="active = 'damagereportform'">
            <router-link to="/damagereportform">Schadensmeldung</router-link>
          </a>
          <a v-if="$auth.isAuthenticated" class="navbar-item is-tab" :class="{'is-active':active === 'damagelist'}" @click="active = 'damagelist'">
            <router-link to="/damagelist" >Schadensübersicht</router-link>
          </a>
          <a v-if="$auth.isAuthenticated" class="navbar-item is-tab" :class="{'is-active':active === 'wiki'}" @click="active = 'wiki'">
            <router-link to="/wiki">Mitarbeiterhandbuch</router-link>
          </a>
        </div>
        <div v-if="!$auth.loading">
          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <a class="button is-primary" v-if="!$auth.isAuthenticated" @click="login">
                  <strong>Anmelden</strong>
                </a>
                <a class="button is-light" v-if="$auth.isAuthenticated" @click="logout">
                  Abmelden
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "app",

  data: function () {
    return {
      showNav: false,
      active: 'home'
    }
  },
  mounted() {
    document.title = 'Stars Fitness Intranet'
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }

};
</script>


<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>