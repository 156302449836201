<template>
  <div>
    <div class="home box has-background-info-light has-text-info-dark">
      <p>Hier können Sie eine Schadensmeldung übermitteln. Alle Felder sind Pflichteingaben. Photo/Bilddatei ist optional.</p>
    </div>
    <div class="container">
      <section class="section">
        <div class="field">
          <label class="label is-large">Vor- und Nachname</label>
          <div class="control has-icons-left">
            <input v-model="fullName" class="input is-large" type="text" placeholder="Vorname Nachname">
            <span class="icon is-small is-left">
              <font-awesome-icon icon="user"/>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label is-large">Email</label>
          <div class="control has-icons-left has-icons-right">
            <input v-model="email" class="input is-large" type="email" placeholder="Optional">
            <span class="icon is-small is-left">
              <font-awesome-icon icon="envelope"/>
            </span>
            <span class="icon is-small is-right">
              <!--<font-awesome-icon icon="exclamation-triangle"/>-->
            </span>
          </div>
          <p class="help is-danger"></p>
        </div>
        <div class="field">
          <label class="label is-large">Standort</label>
          <div class="control">
            <div class="select">
              <select v-model="location">
                <option :value="null" disabled>Club auswählen</option>
                <option>Klosterneuburg</option>
                <option>Wien Rennweg</option>
                <!--<option>Wien Schottenring</option>
                <option>St. Pölten</option>-->
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label is-large">Zuständigkeit</label>
          <div class="control">
            <div class="select">
              <select v-model="responsibility">
                <option :value="null" disabled>Person auswählen</option>
                <option value="bernhard.anderl@starsfitness.at">Bernhard Anderl</option>
                <option value="herbert.hack1@chello.at">Herbert Hack</option>
                <option value="paul.lukan@starsfitness.at">Paul Lukan</option>
                <option value="willi@starsfitness.at">Willi Ruiss</option>
                <!--<option value="michael@subversus.at">Michael Strommer (für Testzwecke)</option>
                <option value="michael.strommer@edu.fh-campuswien.ac.at">FH</option>-->
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label is-large">Schadensbeschreibung</label>
          <div class="control">
            <textarea v-model="report" class="textarea" placeholder="Textarea"></textarea>
          </div>
        </div>
        <div class="file has-name is-danger">
          <label class="file-label">
            <input class="file-input" type="file" accept="image/png, image/jpeg" name="resume" @change="uploadImage($event)">
            <span class="file-cta">
              <span class="file-icon">
                <font-awesome-icon icon="cloud-upload-alt"/>
              </span>
              <span class="file-label">
               Foto auswählen/aufnehmen
              </span>
            </span>
            <span v-if="imageName" class="file-name">{{ imageName }}</span>
            <span v-else class="file-name">Keine Datei ausgewählt</span>
          </label>
        </div>
        <div v-if="previewImage"><img :src="previewImage" class="uploading-image" /></div>
        <div class="field is-grouped mt-3">
          <div class="control">
            <button class="button is-link" @click="submitReport" :disabled=isDisabled>Submit</button>
          </div>
          <div class="control">
            <button class="button is-link is-light" @click="cancelReport">Cancel</button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import router from '../router';

export default {
  name: "damagereportform",

  data: function () {
    return {
      fullName:null,
      email:this.$auth.user.email,
      location:null,
      responsibility:null,
      report:null,
      previewImage:null,
      allowableTypes: ['jpg', 'jpeg', 'png', 'gif'],
      maximumSize: 5000000,
      image: null,
      imageName: null,
      validationComplete: false
    }
  },
  mounted() {
    document.title = 'Stars Fitness Intranet'
  },
  computed: {
    isDisabled() {
      if(this.email != null && this.fullName != null && this.report != null && this.location != null && this.responsibility != null){
        return !(this.email.length !== 0 && this.fullName.length !== 0 && this.report.length !== 0 && this.location.length !== 0
            && this.responsibility.length !== 0);
      } else {
        return true;
      }
    }
  },
  /*
  isDisabled() {
    if(this.email != null && this.fullName != null && this.report != null && this.location != null && this.responsibility != null){
      if(this.email.length !== 0 && this.fullName.length !== 0 && this.report.length !== 0 && this.location.length !== 0
          && this.responsibility.length !== 0){
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  },*/
  methods: {
    cancelReport(){
      router.push({ path: '/' })
    },
    submitReport(){
      //const URL = '/api/send.php';
      let formData = new FormData();
      formData.append('fullName', this.fullName);
      formData.append('username', this.$auth.user.name);
      formData.append('email', this.email);
      formData.append('location', this.location);
      formData.append('responsibility', this.responsibility);
      formData.append('report', this.report);
      formData.append('file', this.image);

      const options = {
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: formData,
        url: 'api/send.php',
      };

      axios(options).then(
          response => {
            console.log('image uploads response > ', response)
          }
      )
      router.push({ path: '/DamageSubmitted' })

    },
    uploadImage(e) {
      this.image = e.target.files[0];
      this.imageName = e.target.files[0].name;
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onload = e =>{
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
      /*
      const URL = 'http://foobar.com/upload';

      let data = new FormData();
      data.append('name', 'my-picture');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }

      axios.put(
          URL,
          data,
          config
      ).then(
          response => {
            console.log('image uploads response > ', response)
          }
      )
      */
    }
  }
};
</script>

<style>
.uploading-image{
  display:flex;
}
</style>