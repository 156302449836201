<template>
  <div>
    <div class="home box has-background-info-light has-text-info-dark">
      <p>Hier können von Admins und Clubmanager*innen die Akquisitionen der Mitarbeiter*innen verwaltet werden.</p>
    </div>
    <div class="container">
      <div class="subtitle">Akquise Management</div>
      <div v-if="loading">Daten werden geladen...</div>
      <div v-else>
        <div>Geben Sie die Daten für Mitarbeiter*in</div>
        <div class="is-uppercase">{{ employee.firstname }} {{ employee.lastname }}</div>
        <div>ein.</div>
        <div class="table-container">
          <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
            <tr>
              <th>Monat</th>
              <th>Jahr</th>
              <th>Zielvorgabe</th>
              <th>Abschlüsse o. Probetr.</th>
              <th>Wiedereinst.</th>
              <th>Abschlüsse nach Probetr.</th>
              <th>Probe Tr.</th>
              <th>Touren</th>
              <th>Aktionen</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(acquisition, index) in acquisitions" :key="acquisition.id">
              <td class="is-vcentered" @click="editAcquisition(index)">{{ getMonthName(acquisition.month) }}</td>
              <td class="is-vcentered" @click="editAcquisition(index)">{{ acquisition.year }}</td>
              <td class="is-vcentered" @click="editAcquisition(index)">{{ acquisition.target }}</td>
              <td>
                  <div class="columns is-vcentered">
                      <div class="column">{{ acquisition.actual }}</div>
                      <div class="column field is-grouped buttons are-small is-center">
                          <div class="control">
                              <button class="button is-link px-4 is-warning" @click="increaseAcquisitionsByOne(index)">+</button>
                          </div>
                          <div class="control">
                              <button class="button is-link px-4 is-danger" @click="decreaseAcquisitionsByOne(index)">-</button>
                          </div>
                      </div>
                  </div>
              </td>
              <td class="is-vcentered" @click="editAcquisition(index)">{{ acquisition.comeback }}</td>
              <td class="is-vcentered" @click="editAcquisition(index)">{{ acquisition.aftertrial }}</td>
              <td class="is-vcentered" @click="editAcquisition(index)">{{ acquisition.trials }}</td>
              <td class="is-vcentered" @click="editAcquisition(index)">{{ acquisition.tours }}</td>
              <td class="is-vcentered">
                <div class="field is-grouped buttons are-small">
                  <div class="control">
                    <button class="button is-link px-4 is-warning" @click="editAcquisition(index)">Bearbeiten</button>
                  </div>
                  <div class="control">
                    <button class="button is-link px-4 is-danger" @click="deleteAcquisition(index)">Löschen</button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <button class="button px-4" @click="back()">Zurück</button>
      </div>
      <div class="field is-grouped buttons">
        <div class="control">
          <button class="button is-link px-4 is-link" v-for="(month) in months" :key="month.id" @click="createAcquisition(month)">{{month.name}} {{month.year}}</button>
        </div>
      </div>
      <div v-if="showModal" class="overlay">
        <div class="columns is-justify-content-center modal">
          <div class="column is-6-tablet is-5-desktop is-4-widescreen is-3-fullh box p-6">
            <div class="subtitle is-block mb-4">{{ getMonthName(currentAcquisition.month) }} {{currentAcquisition.year}}</div>
            <div class="subtitle is-block mb-4">Detailansicht zur Bearbeitung. Es müssen alle Felder ausgefüllt werden.</div>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label prevent-wrap">Zielvorgabe (Soll)</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <input v-model="currentAcquisition.target" id="target" type="text" class="input" placeholder="Zielvorgabe"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label prevent-wrap">Abschlüsse ohne Probetrainings (Ist)</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <input v-model="currentAcquisition.actual" id="actual" type="text" class="input" placeholder="Abschlüsse ohne Probetraining"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label prevent-wrap">Abschlüsse nach Probetrainings (Ist)</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <input v-model="currentAcquisition.aftertrial" id="actual" type="text" class="input" placeholder="Abschlüsse nach Probetraining"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                  <label class="label prevent-wrap">Wiedereinsteiger (Ist)</label>
              </div>
              <div class="field-body">
                  <div class="field">
                      <div class="control">
                          <input v-model="currentAcquisition.comeback" id="actual" type="text" class="input" placeholder="Wiedereinsteiger"/>
                      </div>
                  </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                  <label class="label prevent-wrap">Probetrainings</label>
              </div>
              <div class="field-body">
                  <div class="field">
                      <div class="control">
                          <input v-model="currentAcquisition.trials" id="target" type="text" class="input" placeholder="Probetrainings"/>
                      </div>
                  </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                  <label class="label prevent-wrap">Clubtouren</label>
              </div>
              <div class="field-body">
                  <div class="field">
                      <div class="control">
                          <input v-model="currentAcquisition.tours" id="actual" type="text" class="input" placeholder="Clubtouren"/>
                      </div>
                  </div>
              </div>
            </div>
            <div class="field is-grouped buttons are-small">
              <div class="control">
                <button class="button is-link px-4 is-info" @click="saveAcquisition()" :disabled=isDisabled>Speichern</button>
              </div>
              <div class="control">
                <button class="button px-4" @click="closeModal()">Abbrechen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import router from "../router";
export default {
  data() {
    return {
      monthNames: ["Jänner","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
      test: "Hello World",
      employee: null,
      loading: true,
      months: [],
      acquisitions: [],
      currentAcquisition: {
        id: null,
        employeeId: "",
        actual: "",
        aftertrial: "",
        comeback: "",
        target: "",
        trials: "",
        tours: "",
        month: "",
        year: ""
      },
      showModal: false
    };
  },
  computed: {
      isDisabled() {
          if(this.currentAcquisition.target != null &&
              this.currentAcquisition.actual != null &&
              this.currentAcquisition.aftertrial != null &&
              this.currentAcquisition.comeback != null &&
              this.currentAcquisition.trials != null &&
              this.currentAcquisition.tours != null ){
              return !(this.currentAcquisition.target.length !== 0 &&
                  this.currentAcquisition.actual.length !== 0 &&
                  this.currentAcquisition.aftertrial.length !== 0 &&
                  this.currentAcquisition.comeback.length !== 0 &&
                  this.currentAcquisition.trials.length !== 0 &&
                  this.currentAcquisition.tours.length !== 0);
          } else {
              return true;
          }
      }
  },
  methods: {
    fetchEmployee(index) {
      fetch(`https://intranet.starsfitness.at/api/manageEmployee.php?timestamp=` + Date.now() + `&id=` + index) // Date.now() to avoid Browser Caching
          .then(response => response.json())
          .then(data => this.employee = data)
          .finally(() => this.fetchAcquisitions())
    },
    fetchAcquisitions() {
      fetch(`https://intranet.starsfitness.at/api/manageAcquisition.php?timestamp=` + Date.now() + `&employeeId=` + this.employee.id)
          .then(response => response.json())
          .then(data => this.acquisitions = data)
          .finally(() => this.loading = false)
          .finally(() => this.computeMonths())
    },
    computeMonths(){
        this.months = [];
        let currentDate = new Date();
        if(!this.acquisitions.find(item => item.month === currentDate.getMonth() && item.year === currentDate.getFullYear())) {
            this.months.push({
                id: currentDate.getMonth(),
                name: this.monthNames[currentDate.getMonth()],
                year: currentDate.getFullYear()
            });
        }
        if(currentDate.getMonth()===11) {
            if (!this.acquisitions.find(item => item.month === 0 && item.year === currentDate.getFullYear() + 1)) {
                this.months.push({id: 0, name: this.monthNames[0], year: currentDate.getFullYear() + 1});
            }
        }
        else {
            if (!this.acquisitions.find(item => item.month === currentDate.getMonth() + 1 && item.year === currentDate.getFullYear())) {
                this.months.push({
                    id: currentDate.getMonth() + 1,
                    name: this.monthNames[currentDate.getMonth() + 1],
                    year: currentDate.getFullYear()
                });
            }
        }
    },
    createAcquisition(month) {
      // Reset the acquisition object and show the modal window for creating a new acquisition
      this.currentAcquisition = {
        id: null,
        employeeId: this.employee.id,
        actual: "",
        aftertrial: "",
        comeback: "",
        target: "",
        trials: "",
        tours: "",
        month: month.id,
        year: month.year
      };
      this.showModal = true;
    },
    saveAcquisition() {
      if (this.currentAcquisition.id) {
        fetch("https://intranet.starsfitness.at/api/manageAcquisition.php", {
          method: "PUT",
          body: JSON.stringify(this.currentAcquisition)
        })
            .then(response => response.json())
            .then(data => {
              // Update the acquisitions array with the updated acquisition object
              const index = this.acquisitions.findIndex(acq => acq.id === data.id);
              this.acquisitions.splice(index, 1, data);
              // Update Buttons
              this.computeMonths();
              // Close the modal window
              this.closeModal();
            });
      } else {
        fetch("https://intranet.starsfitness.at/api/manageAcquisition.php?employeeId=" + this.employee.id, {
          method: "POST",
          body: JSON.stringify(this.currentAcquisition)
        })
            .then(response => response.json())
            .then(data => {
              // Add the new acquisition object to the acquisitions array
              this.acquisitions.push(data);
              // Update Buttons
              this.computeMonths();
              // Close the modal window
              this.closeModal();
            });
      }
    },
    increaseAcquisitionsByOne(index){
      this.currentAcquisition = { ...this.acquisitions[index] };
      this.currentAcquisition.actual++;
      this.saveAcquisition();
    },
    decreaseAcquisitionsByOne(index){
      this.currentAcquisition = { ...this.acquisitions[index] };
      this.currentAcquisition.actual--;
      this.saveAcquisition();
    },
    editAcquisition(index) {
      // Copy the acquisition object at the specified index to currentAcquistion and show the modal window for editing
      this.currentAcquisition = { ...this.acquisitions[index] };
      this.showModal = true;
    },
    deleteAcquisition(index) {
      const confirmed = confirm("Sind Sie sicher, dass der Eintrag gelöscht werden soll?");
      if (confirmed) {
        console.log(index);
        const id = this.acquisitions[index].id;
        fetch(`https://intranet.starsfitness.at/api/manageAcquisition.php?id=${id}`, {
          method: "DELETE",
          headers: {
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:8080/',
            'Access-Control-Request-Method': 'DELETE'
          }
        }).then(() => {
              // Remove the employee object from the employees array
              this.acquisitions.splice(index, 1);
        }).finally(() => this.computeMonths());
      }
    },
    back(){
      router.push({ path: '/EmployeeManagement' })
    },
    getMonthName(id){
      return this.monthNames[id]
    },
    closeModal() {
      // Reset the currentEmployee object and hide the modal window
      this.currentAcquisition = {
        id: null,
        employeeId: "",
        actual: "",
        aftertrial: "",
        comeback: "",
        target: "",
        trials: "",
        tours: "",
        month: "",
        year: ""
      };
      this.showModal = false;
    }
  },
  mounted() {
    this.fetchEmployee(this.$route.params.id);
    document.title = 'Stars Fitness Intranet';
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      this.to = to;
      this.from = from;
      this.loading = true;
      this.fetchEmployee(to.params.id);
    }
  }
};
</script>

<style>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.prevent-wrap {
    white-space: nowrap;
}
</style>
