<template>
  <div>
    <button @click="callApi">Call</button>
    <p>{{ apiMessage }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "damage",
  data() {
    return {
      apiMessage: ''
    };
  },
  methods: {
    async callApi() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      // Use Axios to make a call to the API
      // https://auth0.com/docs/quickstart/spa/vuejs/02-calling-an-api?framed=1&sq=1#call-the-api-using-an-access-token
      const { data } = await axios.get("https://intranet.starsfitness.at/api/private", {
        headers: {
          Authorization: `Bearer ${token}`    // send the access token through the 'Authorization' header
        }
      });

      this.apiMessage = data;
    }
  }
};
</script>