<template>
  <div>
    <div class="home box has-background-info-light has-text-info-dark">
      <p>Hier können Sie die Zuständigkeit ändern und die Schadensbeschreibung aktualisieren.</p>
    </div>
    <div class="container">
      <section class="section">
        <div v-if="loading">Schadensmeldung wird geladen...</div>
        <div v-else-if="message">{{message}}</div>
        <div v-else>
          <div class="field">
            <label class="label is-large">Schadensbeschreibung</label>
            <div class="control">
              <textarea v-model="damage.report" class="textarea" placeholder="Textarea"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label is-large">Zuständigkeit</label>
            <div class="control">
              <div class="select">
                <select v-model="damage.responsibility">
                  <option :value="null" disabled>Person auswählen</option>
                  <option value="bernhard.anderl@starsfitness.at">Bernhard Anderl</option>
                  <option value="herbert.hack1@chello.at">Herbert Hack</option>
                  <option value="paul.lukan@starsfitness.at">Paul Lukan</option>
                  <option value="willi@starsfitness.at">Willi Ruiss</option>
                  <!--<option value="michael@subversus.at">Michael Strommer (für Testzwecke)</option>-->
                </select>
              </div>
            </div>
          </div>
          <div class="field is-grouped mt-3">
            <div class="control">
              <button class="button is-link" @click="submit" :disabled=isDisabled>Submit</button>
            </div>
            <div class="control">
              <button class="button is-link is-light" @click="cancelEditDamage">Cancel</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from '../router';

export default {
  name: "EditDamage",

  data: function () {
    return {
      loading: true,
      message: null,
      damage: null,
      to: null,
      from: null
    }
  },
  computed: {
    isDisabled() {
      if(!this.loading && this.message == null) {
        if (this.damage.report != null && this.damage.responsibility != null) {
          return !(this.damage.report.length !== 0 && this.damage.responsibility.length !== 0);
        } else {
          return true;
        }
      }
      return true;
    }
  },
  methods: {
    cancelEditDamage(){
      router.push({ path: '/DamageList' })
    },
    submit(){
      //const URL = '/api/send.php';
      let formData = new FormData();
      formData.append('id', this.$route.params.id);
      formData.append('responsibility', this.damage.responsibility);
      formData.append('report', this.damage.report);
      formData.append('email_damage_change', this.$auth.user.email);

      const options = {
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: formData,
        url: 'api/updateDamage.php',
      };

      axios(options).then(
          response => {
            console.log('image uploads response > ', response)
          }
      )
      router.push({ path: '/damagelist' }) // TODO update path to production URL
    },
    getDamage(id){
      axios.get('https://intranet.starsfitness.at/api/getDamage.php?damage_id='+id)
          .then(response => (this.damage = response.data))
          .catch(error => console.log(error))
          .finally(() => {
            this.loading = false;
            this.message = null;
            if(this.damage == null){
              this.message = "Keine Daten gefunden";
            }
          });
    },
  },
  mounted() {
    this.getDamage(this.$route.params.id);
    document.title = 'Stars Fitness Intranet';
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      this.to = to;
      this.from = from;
      this.loading = true;
      this.getDamage(to.params.id);
    }
  }
};
</script>

<style scoped>

</style>