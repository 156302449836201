<template>
  <div>
    <figure class="image is-4by3" @click="imageSrc.includes('placeholder') ? showOverlay = false : showOverlay = true">
      <img :src="imageSrc" class="figure-img img-fluid" style="width: 100%; height: 100%;">
    </figure>
    <div v-if="showOverlay" class="overlay" @click="showOverlay = false">
      <img :src="imageSrc">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: String
  },
  data() {
    return {
      showOverlay: false
    };
  }
};
</script>

<style>
.figure {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.overlay img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}
</style>
