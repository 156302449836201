<template>
  <div>
    <p>hat seine Ziele zu</p>
    <div class="subtitle">{{ (actual / target * 100).toFixed(2)}} %</div>
    <p>erfüllt.</p>
    <progress v-if="Number(this.actual) < Number(this.target)" class="progress is-warning" :value="actual" :max="target * 2"></progress>
    <progress v-else class="progress is-success" :value="actual" :max="target * 2"></progress>
    <p v-if="absolute">Absolute Zahlen (nur für mich sichtbar)</p>
    <p v-if="absolute">Verträge bisher abgeschlossen: {{actual}} <br>Zielvorgabe für diesen Monat: {{target}}</p>
  </div>
</template>

<script>
export default {
  name: "AcquisitionProgress",
  props: {
    actual: String,
    target: String,
    absolute: Boolean
  },
  data: function () {
    return {
      color: ""
    }
  },
  mounted() {
    if(Number(this.actual) < Number(this.target)){
      this.color = "progress is-warning";
    } else if(Number(this.actual) === Number(this.target)){
      this.color = "progress is-success";
    } else {
      this.color = "progress is-success";
    }
  }
}
</script>

<style scoped>

</style>