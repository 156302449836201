<template>
  <div>
    <div class="home box has-background-info-light has-text-info-dark">
      <p>Hier findet sich eine Übersicht der zur Zeit aktuellen Schadensmeldungen.</p>
    </div>
    <div class="container">
      <section v-if="errored">
        <p>Zur Zeit kann die Schadensliste leider nicht angezeigt werden. Versuchen Sie es später erneut.</p>
      </section>
      <section v-else>
        <div v-if="loading">Schadensmeldungen werden geladen...</div>
        <div v-else>
          <div class="tabs is-toggle is-toggle-rounded">
            <ul>
              <li :class="{'is-active': location == 0}">
                <a @click="filterDamages(0, status)">
                  <span>Alle Clubs</span>
                </a>
              </li>
              <li :class="{'is-active': location == 1}">
                <a @click="filterDamages(1, status)">
                  <span>Klosterneuburg</span>
                </a>
              </li>
              <li :class="{'is-active': location == 2}">
                <a @click="filterDamages(2, status)">
                  <span>Rennweg</span>
                </a>
              </li>
              <li :class="{'is-active': location == 3}">
                <a @click="filterDamages(3, status)">
                  <span>St. Pölten</span>
                </a>
              </li>
              <li :class="{'is-active': location == 4}">
                <a @click="filterDamages(4, status)">
                  <span>Schottenring</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="tabs is-toggle is-toggle-rounded">
            <ul>
              <li :class="{'is-active': status == 0}">
                <a @click="filterDamages(location, 0)">
                  <span>Offene Schäden</span>
                </a>
              </li>
              <li :class="{'is-active': status == 1}">
                <a @click="filterDamages(location,1)">
                  <span>Abgeschlossen</span>
                </a>
              </li>
              <li :class="{'is-active': status == 2}">
                <a @click="filterDamages(location,2)">
                  <span>Alle</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="card-container">
            <div v-for="(damage, index) in tmpDamages" :key="damage.id" class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{index+1}}. {{damage.location}}
                </p>
              </header>
              <div v-for="image in (damage.imagePath.split(';'))" :key="image">
                <div class="card-image">
                    <image-overlay :image-src="image ? image.replace('../uploads/','https://intranet.starsfitness.at/uploads/thumbs/') : 'img/placeholder_image.png'"></image-overlay>
                  <!--
                  <img :src="image ? image.replace('uploads/','uploads/thumbs/') : 'img/placeholder_image.png'">
                  <figure v-for="image in (damage.imagePath.split(';'))" :key="image" class="image is-4by3 crop">
                  <image-overlay :image-src="image ? image.replace('../uploads/','https://intranet.starsfitness.at/uploads/thumbs/') : 'img/placeholder_image.png'"></image-overlay>
                   <img :src="image ? image.replace('uploads/','uploads/thumbs/') : 'img/placeholder_image.png'">
                  <div class="card-content is-overlay has-text-left">
                    <span class="tag is-info"><font-awesome-icon icon="magnifying-glass" /></span>
                  </div>
                  -->
                </div>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">{{damage.fullname}}</p>
                    <p class="subtitle is-6">{{damage.email}}</p>
                  </div>
                </div>
                <div class="content has-text-left">
                  {{damage.report}}
                  <br><br>
                  gemeldet am: <br><time :datetime="damage.timeOfReport">{{damage.timeOfReport | formatDate}}</time>
                  <br><br>
                  <div v-if="damage.status == 2">
                    behoben am: <br><time :datetime="damage.timeOfStatusChange" class="has-background-success">{{damage.timeOfStatusChange | formatDate}}</time>
                    <br><br>
                  </div>
                  verantwortlich: <br>
                  <div v-if="damage.responsibility === 'bernhard.anderl@starsfitness.at'">Bernhard Anderl</div>
                  <div v-if="damage.responsibility === 'herbert.hack1@chello.at'">Herbert Hack</div>
                  <div v-if="damage.responsibility === 'paul.lukan@starsfitness.at'">Paul Lukan</div>
                  <div v-if="damage.responsibility === 'willi@starsfitness.at'">Willi Ruiss</div>
                  <div v-else>Nicht hinterlegt.</div>
                  <br>
                  <div class="columns">
                    <div class="column">
                      <button v-if="allowedToEdit()" @click="editDamage(damage.id)" :class="{'is-loading': updating}" class="button is-info">
                        <font-awesome-icon icon="edit" />
                      </button>
                    </div>
                    <div class="column"></div>
                    <div class="column">
                      <div class="file">
                        <div v-if="!updating">
                          <label class="file-label">
                            <input class="file-input" type="file" multiple accept="image/png, image/jpeg" name="resume" @change="handleFileUpload($event, damage.id)">
                            <span class="file-cta">
                              <span class="file-icon">
                                <font-awesome-icon icon="images"/>
                              </span>
                            </span>
                          </label>
                        </div>
                        <div v-else class="control is-loading"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <header class="card-header">
                <p class="card-header-title">Notizen</p>
                <a class="card-header-icon card-toggle" @click="toggleNote(damage.id)">
                  <font-awesome-icon icon="angle-down"/>
                </a>
              </header>
              <div class="card-content" :class="{'is-hidden': showNote != damage.id}">
                <div class="content has-text-left">
                  <div v-for='note in notes' :key='note.id'>
                    <p class="mb-4 has-text-dark">{{ note.note }}<br>{{ note.timeOfNote }}<br>{{ note.email }}</p>
                  </div>
                  <textarea v-model="note" placeholder="Notiz anhängen" class="textarea"></textarea>
                  <div class="control">
                    <button class="button is-link mt-2" @click="addNote(damage.id)" :disabled=isDisabled>Absenden</button>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <button @click.prevent="updateStatus(damage.id, 0)" :class="{'is-loading': updating, 'is-danger': damage.status == 0}" class="button card-footer-item">
                  <font-awesome-icon icon="house-damage" />
                </button>
                <button @click.prevent="updateStatus(damage.id, 1)" :class="{'is-loading': updating, 'is-warning': damage.status == 1}" class="button card-footer-item">
                  <font-awesome-icon icon="tools"/>
                </button>
                <button @click.prevent="updateStatus(damage.id, 2)" :class="{'is-loading': updating, 'is-success': damage.status == 2}" class="button card-footer-item">
                  <font-awesome-icon icon="check-square"/>
                </button>
              </footer>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageOverlay from "../components/ImageOverlay";

export default {
  name: "Damages",
  components: {
    ImageOverlay
  },
  data: function () {
    return {
      role: null,
      damages: null,
      tmpDamages: null,
      notes:null,
      loading: true,
      errored: false,
      updating: false,
      note: null,
      showNote: -1,
      location: 0,
      status: 0,
      render: ["Klosterneuburg", "St. Pölten","Wien Rennweg","Wien Schottenring"],
      technicians: ["bernhard.anderl@starsfitness.at",
        "herbert.hack1@chello.at",
        "paul.lukan@starsfitness.at",
        "willi@starsfitness.at",
        "michael@subversus.at",
        "philip.rosenstingl@starsfitness.at"]
    }
  },

  computed: {
    isDisabled() {
      if(this.note != null){
        return this.note.length <= 1;
      } else {
        return true;
      }
    }
  },
  methods: {
    // not in use anymore
    toggleStatus(id, status){
      this.updating = true;
      let formData = new FormData();
      formData.append('id', id);
      formData.append('status', status);

      const options = {
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: formData,
        url: 'https://intranet.starsfitness.at/api/toggleStatus.php',
      };

      axios(options).then(response => this.updateStatusInList(response.data, status, id))
          .catch(error => console.log(error))
          .finally(() => this.updating = false)
    },
    toggleNote(damage_id){
      if(this.showNote == damage_id){
        this.showNote = -1;
      } else {
        this.showNote = damage_id;
        this.notes = null;
        this.getNotes(damage_id); // plus ID
      }
    },
    addNote(damage_id){
      this.updating = true;
      let formData = new FormData();
      formData.append('damage_id', damage_id);
      formData.append('username', this.$auth.user.nickname);
      formData.append('email', this.$auth.user.email);
      formData.append('note', this.note);

      const options = {
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: formData,
        url: 'https://intranet.starsfitness.at/api/postNote.php',
      };
      axios(options).then(response => console.log('Post of note successful', response))
          .catch(error => console.log(error))
          .finally(() => this.updating = false)
          .finally(() => this.note = null)
          .finally(() => this.getNotes(damage_id));
    },
    getNotes(damage_id){
      this.updating = true;
      let formData = new FormData();
      formData.append('damage_id', damage_id);
      const options = {
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: formData,
        url: 'https://intranet.starsfitness.at/api/getNotesForDamageList.php',
      };
      axios(options).then(response => this.notes = response.data)
          .catch(error => console.log(error))
          .finally(() => this.updating = false)
    },
    updateStatus(id, status){
      this.updating = true;
      let formData = new FormData();
      formData.append('id', id);
      formData.append('status', status);
      formData.append('email_status_change', this.$auth.user.email);

      const options = {
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: formData,
        url: 'https://intranet.starsfitness.at/api/updateStatus.php',
      };
      axios(options).then(response => this.updateStatusInList(response.data, status, id))
          .catch(error => console.log(error))
          .finally(() => this.updating = false)
    },
    updateStatusInList(response, status, id){
      if(response === "success") {
        // find in the array and remove
        // const index = this.damages.indexOf(id);
        const index = this.damages.findIndex(i => i.id == id);
        this.damages[index].status = status;
      }
    },
    filterDamages(location, status){
      this.filterLocation(location);
      this.filterStatus(status);

    },
    filterLocation(location){
      if(location == 0){
        this.tmpDamages = [...this.damages];
        this.location = 0;
      } else if(location == 1){
        this.tmpDamages = this.damages.filter(damage => (damage.location === "Klosterneuburg"));
        this.location = 1;
      } else if(location == 2){
        this.tmpDamages = this.damages.filter(damage => (damage.location === "Wien Rennweg"));
        this.location = 2;
      } else if(location == 3){
        this.tmpDamages = this.damages.filter(damage => (damage.location === "St. Pölten"));
        this.location = 3;
      } else if(location == 4){
        this.tmpDamages = this.damages.filter(damage => (damage.location === "Wien Schottenring"));
        this.location = 4;
      }
    },
    filterStatus(status){
      if(status == 0){
        this.tmpDamages = this.tmpDamages.filter(damage => (damage.status == 0 || damage.status == 1));
        this.status = 0;
      } else if(status == 1){
        this.tmpDamages = this.tmpDamages.filter(damage => (damage.status == 2));
        this.status = 1;
      } else if(status == 2){
        this.tmpDamages = this.tmpDamages.filter(damage => (damage.status == 0 || damage.status == 1 || damage.status == 2));
        this.status = 2;
      }
    },
    editDamage(id){
      this.$router.push('/editdamage/' + id);
    },
    allowedToEdit(){
      return this.technicians.includes(this.$auth.user.email);
    },
    async handleFileUpload(e, id) {
      this.updating = true;
      const formData = new FormData();
      formData.append('id', id);
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("images[]", e.target.files[i]);
      }
      try {
        const response = await axios.post("https://intranet.starsfitness.at/api/addImages.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.damages = this.tmpDamages = response.data;
        this.filterDamages(this.location, this.status);
        this.updating = false;
        console.log(response.data);
      } catch (error) {
        this.updating = false;
        console.error(error);
      }
    },
  },
  mounted() {
    axios.get('https://intranet.starsfitness.at/api/getDamageList.php')
        .then(response => (this.damages = this.tmpDamages = response.data))
        .catch(error => console.log(error))
        .finally(() => this.loading = false)
        .finally(() => this.filterDamages(location, status));
    document.title = 'Stars Fitness Intranet';
  }
}
</script>

<style scoped>
.card-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
  grid-gap: 0.5em;
  margin-top: 20px;
}

.crop img {
  height: 100%; object-fit: cover;
}
.card {
  height: max-content;
}


</style>