import Vue from "vue";
import App from "./App.vue";
import router from './router'
import moment from 'moment';
import 'bulma/css/bulma.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faEnvelope, faDumbbell, faPersonRunning, faMedal, faCloudUploadAlt, faCheck, faCheckSquare, faTools, faExclamationTriangle, faImages, faHouseDamage, faAngleDown, faEdit, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faEnvelope)
library.add(faUser)
library.add(faCloudUploadAlt)
library.add(faCheck)
library.add(faCheckSquare)
library.add(faTools)
library.add(faExclamationTriangle)
library.add(faHouseDamage)
library.add(faAngleDown)
library.add(faEdit)
library.add(faMedal)
library.add(faDumbbell)
library.add(faImages)
library.add(faPersonRunning)
library.add(faMagnifyingGlass)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Import the Auth0 configuration
import { domain, clientId, audience} from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
  }
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:MM')
  }
})

Vue.filter('getName', function(value) {
  if (value) {
    const arr = value.split("@")[0].split(".")
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    return arr.join(" ")
  }
})

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");