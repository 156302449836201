<template>
  <div class="box has-background-info-light has-text-info-dark">Danke für die Übersendung des Schadensreports.</div>
</template>

<script>
export default {
  name: "damagesubmitted",
  mounted() {
    document.title = 'Stars Fitness Intranet'
  },
  data: function () {
    return {
      fullname: null
    }
  }
};

</script>