<template>
  <div>
    <div class="home box has-background-info-light has-text-info-dark">
      <p>Willkommen zum Intranet von Stars Fitness!</p>
      <p v-if="$auth.isAuthenticated">Bug Reporting und Feature Requests erfolgen über ein Trello Board. Bitte
         <a href="https://trello.com/invite/b/t672TWVZ/d27c4b63ad2fb6a0d2dfa9b03eaf012f/intranet" target="_blank">hier klicken</a>
      um einen Account anzulegen und so dem Board beizutreten.</p>
      <p v-if="$auth.isAuthenticated">
        Version 3.0: Mitarbeiter*innenverwaltung und Vertragsabschlüsse. Dashboard.<br>
        Version 2.0: Es können jetzt nach Erstellung einer Meldung mehrere Bilder hochgeladen werden. Bilder lassen sich vergrößern.
      </p>
    </div>
    <div v-if="$auth.isAuthenticated">
      <div>
      Hallo {{this.$auth.user.name}}, willkommen zurück!
      </div>
      <div>
        <damage-reported />
        <damage-responsible />
      </div>
    </div>
  </div>
</template>

<script>
import DamageReported from "../components/DamageReported";
import DamageResponsible from "../components/DamageResponsible";

export default {
  name: "home",
  components: {DamageResponsible, DamageReported},
  mounted() {
    document.title = 'Stars Fitness Intranet'
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>