<template>
  <div>
    <div class="home box has-background-info-light has-text-info-dark">
      <p>Hier können von Admins die Mitarbeiter*innen verwaltet werden. Hier geht es zum <a href="/#/AcquisitionDashboard">Dashboard</a>.</p>
    </div>
    <div class="container">
      <div class="subtitle">Liste der Mitarbeiter*innen</div>
      <!--<h2>{{result}}</h2>
      <ClickToEdit
          :value="test"
          @input-event="(n) => result = n"
      />-->
      <div v-if="loading">Mitarbeiter*innen werden geladen...</div>
      <div v-else class="table-container">
        <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
          <tr>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Email</th>
            <th>Telefonnummer</th>
            <th>Club</th>
            <th>Rolle</th>
            <th>Foto</th>
            <th>Aktion</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(employee, index) in employees" :key="employee.id">
              <td class="is-vcentered" @click="editEmployee(index)">{{ employee.firstname }}</td>
              <td class="is-vcentered" @click="editEmployee(index)">{{ employee.lastname }}</td>
              <td class="is-vcentered" @click="editEmployee(index)">{{ employee.email }}</td>
              <td class="is-vcentered" @click="editEmployee(index)">{{ employee.phone }}</td>
              <td class="is-vcentered" @click="editEmployee(index)">{{ employee.location }}</td>
              <td class="is-vcentered" @click="editEmployee(index)">{{ getRoleName(employee.role) }}</td>
              <td class="is-vcentered" @click="editEmployee(index)">Feature in Kürze verfügbar<!--<img :src="employee.image_url" alt="Employee Image">--></td>
              <td class="is-vcentered">
                <div class="field is-grouped buttons are-small">
                  <div class="control">
                    <button class="button is-link px-4 is-info" @click="editAcquisition(employee.id)">Akquisitionen</button>
                  </div>
                  <div class="control">
                    <button class="button is-link px-4 is-warning" @click="editEmployee(index)">Bearbeiten</button>
                  </div>
                  <div class="control">
                    <button class="button is-link px-4 is-danger" @click="deleteEmployee(index)">Löschen</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!loading">
        <button class="button is-link px-4" @click="createEmployee()">Mitarbeiter*in hinzufügen</button>
      </div>
      <div>
        <button class="button px-4 mt-2" @click="back()">Zurück</button>
      </div>
      <div>
          <br><br><br><br>
      </div>
      <div v-if="showModal" class="overlay">
        <div class="columns is-justify-content-center modal">
          <div class="column is-6-tablet is-5-desktop is-4-widescreen is-3-fullh box p-6">
            <label class="is-block mb-4" for="firstname">
              <span class="is-block mb-2">Vorname</span>
              <input v-model="currentEmployee.firstname" id="firstname" type="text" class="input" placeholder="Vorname"/>
            </label>

            <label class="is-block mb-4" for="lastname">
              <span class="is-block mb-2">Nachname</span>
              <input v-model="currentEmployee.lastname" id="lastname" type="text" class="input" placeholder="Nachname"/>
            </label>

            <label class="is-block mb-4" for="email">
              <span class="is-block mb-2">Email</span>
              <input v-model="currentEmployee.email" id="email" type="text" class="input" placeholder="Email"/>
            </label>

            <label class="is-block mb-4" for="phone">
              <span class="is-block mb-2">Telefonnummer</span>
              <input v-model="currentEmployee.phone" id="phone" type="text" class="input" placeholder="Telefonnummer"/>
            </label>

            <label class="is-block mb-4">
              <span class="is-block mb-2">Standort</span>
              <span class="select is-fullwidth">
                <select v-model="currentEmployee.location">
                  <option :value="null" disabled>Club auswählen</option>
                  <option>Klosterneuburg</option>
                  <option>Wien Rennweg</option>
                </select>
              </span>
            </label>

            <label class="is-block mb-4">
              <span class="is-block mb-2">Rolle</span>
              <span class="select is-fullwidth">
                <select v-model="currentEmployee.role">
                  <option :value="null" disabled>Rolle auswählen</option>
                  <option :value="0">Trainer*in</option>
                  <option :value="1">Clubmanager*in</option>
                </select>
              </span>
            </label>
            <div>
              <label for="image_url">Photo</label>
              <!--<input type="text" id="image_url" v-model="currentEmployee.image_url">-->
              <p>Feature steht in Kürze zur Verfügung</p>
            </div>
            <div class="field is-grouped mt-3">
              <div class="control">
                <button class="button is-link px-4" @click="saveEmployee()">Save</button>
              </div>
              <div class="control">
                <button class="button px-4" @click="closeModal()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ClickToEdit from "../components/ClickToEdit.vue";
import router from "../router";

export default {
  //components: {ClickToEdit},
  data() {
    return {
      test: "Hello World",
      result: "nix",
      loading: true,
      employees: [],
      roleNames: ["Trainer*in","Clubmanager*in","Admin"],
      currentEmployee: {
        id: null,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        location: null,
        role: null,
        image_url: ""
      },
      showModal: false
    };
  },
  // https://www.slimframework.com/docs/v4/start/installation.html
  mounted() {
    // Fetch employees data from the PHP API and update the "employees" array
    this.fetchEmployees();
  },
  methods: {
    fetchEmployees() {
      fetch(`https://intranet.starsfitness.at/api/manageEmployee.php?timestamp=` + Date.now())
      .then(response => response.json())
      .then(data => this.employees = data)
      .finally(() => this.loading = false)
    },
    clickRow(){
      confirm("Sind Sie sicher, dass der Mitarbeiter gelöscht werden soll?");
    },
    createEmployee() {
      // Reset the currentEmployee object and show the modal window for creating a new employee
      this.currentEmployee = {
        id: null,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        location: null,
        role: null,
        image_url: ""
      };
      this.showModal = true;
    },
    editAcquisition(index){
      this.$router.push('/AcquisitionManagement/' + index);
    },
    editEmployee(index) {
      // Copy the employee object at the specified index to currentEmployee and show the modal window for editing
      this.currentEmployee = { ...this.employees[index] };
      this.showModal = true;
    },
    saveEmployee() {
      if (this.currentEmployee.id) {
        fetch("https://intranet.starsfitness.at/api/manageEmployee.php", {
          method: "PUT",
          body: JSON.stringify(this.currentEmployee)
        })
        .then(response => response.json())
        .then(data => {
          // Update the employees array with the updated employee object
          const index = this.employees.findIndex(emp => emp.id === data.id);
          this.employees.splice(index, 1, data);
          // Close the modal window
          this.closeModal();
        });
      } else {
        fetch("https://intranet.starsfitness.at/api/manageEmployee.php", {
          method: "POST",
          body: JSON.stringify(this.currentEmployee)
        })
        .then(response => response.json())
        .then(data => {
        // Add the new employee object to the employees array
        this.employees.push(data);
        // Close the modal window
        this.closeModal();
        });
      }
    },
    deleteEmployee(index) {
      const confirmed = confirm("Sind Sie sicher, dass der Mitarbeiter gelöscht werden soll?");
      if (confirmed) {
        console.log(index);
        const id = this.employees[index].id;
        fetch(`https://intranet.starsfitness.at/api/manageEmployee.php?id=${id}`, {
          method: "DELETE",
          headers: {
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:8080/',
            'Access-Control-Request-Method': 'DELETE'
          }
        })
        .then(() => {
          // Remove the employee object from the employees array
          this.employees.splice(index, 1);
        });
      }
    },
    getRoleName(id){
      return this.roleNames[id]
    },
    closeModal() {
      // Reset the currentEmployee object and hide the modal window
      this.currentEmployee = {
        id: null,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        location: "",
        role: "",
        image_url: ""
      };
      this.showModal = false;
    },
    back(){
        router.push({ path: '/AcquisitionDashboard' })
    }
  }
};
</script>

<style>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
</style>
