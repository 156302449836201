<template>
  <div class="container">
    <section v-if="errored">
      <p>Zur Zeit kann die Schadensliste leider nicht angezeigt werden. Versuchen Sie es später erneut.</p>
    </section>
    <section v-else>
      <div v-if="loading">Schadensmeldungen werden geladen...</div>
      <div v-else>
        <div v-if="damages.length != 0" class="home box has-background-info-light has-text-info-dark">
          Folgende Meldungen wurden von Dir eingebracht und sind noch nicht abgearbeitet:
        </div>
        <div class="card-container">
          <div v-for="(damage, index) in damages" :key="damage.id" class="card">
            <header class="card-header">
              <p v-if="damage.status == 0" class="card-header-title has-background-danger has-text-white">
                {{index+1}}. {{damage.location}}
              </p>
              <p v-if="damage.status == 1" class="card-header-title has-background-warning">
                {{index+1}}. {{damage.location}}
              </p>
            </header>
            <div class="card-image">
              <figure v-for="image in (damage.imagePath.split(';'))" :key="image" class="image is-4by3 crop">
                <img :src="image ? image.replace('uploads/','uploads/thumbs/') : 'img/placeholder_image.png'">
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">{{damage.fullname}}</p>
                  <p class="subtitle is-6">{{damage.email}}</p>
                </div>
              </div>
              <div class="content has-text-left">
                {{damage.report}}
                <br><br>
                gemeldet am: <br><time :datetime="damage.timeOfReport">{{damage.timeOfReport | formatDate}}</time>
                <br><br>
                <div v-if="damage.status == 2">
                  behoben am: <br><time :datetime="damage.timeOfStatusChange" class="has-background-success">{{damage.timeOfStatusChange | formatDate}}</time>
                  <br><br>
                </div>
                verantwortlich: <br>
                <div v-if="damage.responsibility === 'bernhard.anderl@starsfitness.at'">Bernhard Anderl</div>
                <div v-if="damage.responsibility === 'herbert.hack1@chello.at'">Herbert Hack</div>
                <div v-if="damage.responsibility === 'paul.lukan@starsfitness.at'">Paul Lukan</div>
                <div v-if="damage.responsibility === 'willi@starsfitness.at'">Willi Ruiss</div>
                <div v-else>Nicht hinterlegt.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "damageReported",
  data: function () {
    return {
      damages: null,
      loading: true,
      errored: false,
      updating: false
    }
  },
  methods: {
    filterReported(email){
      this.damages = this.damages.filter(damage => (damage.email == email && (damage.status == 0 || damage.status == 1)));
    }
  },
  mounted() {
    axios.get('https://intranet.starsfitness.at/api/getDamageList.php')
        .then(response => (this.damages = response.data))
        .catch(error => console.log(error))
        .finally(() => this.loading = false)
        .finally(() => this.filterReported(this.$auth.user.email));
    document.title = 'Stars Fitness Intranet';
  }
};
</script>

<style scoped>
.card-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
  grid-gap: 0.5em;
  margin-top: 20px;
}
.card{
  height: max-content;
}
.crop img {
  height: 100%; object-fit: cover;
}
</style>