import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import DamageReportForm from '../views/DamageReportForm.vue'
import DamageSubmitted from '../views/DamageSubmitted.vue'
import DamageList from '../views/DamageList.vue'
import Wiki from '../views/Wiki.vue'
import EditDamage from '../views/EditDamage.vue'
import Damage from '../views/Damage.vue'
import Test from '../views/Test.vue'
import EmployeeManagement from '../views/EmployeeManagement.vue'
import AcquisitionManagement from "../views/AcquisitionManagement.vue";
import AcquisitionDashboard from "../views/AcquisitionDashboard.vue";
import { authGuard } from "../auth/authGuard"

Vue.use(Router)

export default new Router({
    mode: 'hash', // history needs server side adjustments: https://stackoverflow.com/questions/36399319/vue-router-return-404-when-revisit-to-the-url
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/damagereportform',
            name: 'damagereportform',
            component: DamageReportForm,
            beforeEnter: authGuard
        },
        {
            path: '/DamageSubmitted',
            name: 'damagesubmitted',
            component: DamageSubmitted,
            beforeEnter: authGuard
        },
        {
            path: '/DamageList',
            name: 'damagelist',
            component: DamageList,
            beforeEnter: authGuard
        },
        {
            path: '/Wiki',
            name: 'wiki',
            component: Wiki,
            beforeEnter: authGuard
        },
        {
            path: '/EditDamage/:id',
            name: 'editdamage',
            component: EditDamage,
            beforeEnter: authGuard
        },
        {
            path: '/Damage',
            name: 'damage',
            component: Damage,
            beforeEnter: authGuard
        },
        {
            path: '/Test',
            name: 'test',
            component: Test,
            beforeEnter: authGuard
        },
        {
            path: '/EmployeeManagement',
            name: 'employeemanagement',
            component: EmployeeManagement,
            beforeEnter: authGuard
        },
        {
            path: '/AcquisitionManagement/:id',
            name: 'acquisitionmanagement',
            component: AcquisitionManagement,
            beforeEnter: authGuard
        },
        {
            path: '/AcquisitionDashboard',
            name: 'acquisitiondashboard',
            component: AcquisitionDashboard,
            beforeEnter: authGuard
        }
    ]
})